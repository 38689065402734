import React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import styled from "@emotion/styled";
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import london from "../img/london.jpg";
import londonSmall from "../img/london-small.jpg";
import ayaka from "../img/people/ayaka.jpeg";
import chris from "../img/people/chris.jpg";
import emily from "../img/people/emily.jpeg";
import jenny from "../img/people/jenny.jpg";
import julien from "../img/people/julien.jpg";
import paulina from "../img/people/paulina.jpeg";
import sahanaa from "../img/people/sahanaa.jpeg";
import shefali from "../img/people/shefali.jpg";

const peopleList = [ayaka, chris, emily, jenny, julien, paulina, sahanaa, shefali];

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'rgba(43,0,77,0.95)',
    textAlign: 'center',
    padding: theme.spacing(2),
}));

const Image = styled('img')(({ theme }) => ({
    width: "100%",
    height: "auto",
    objectFit: "contain",
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.up('sm')]: {
        minWidth: "200px", // Prevent images from shrinking too much
    },
    [theme.breakpoints.down('sm')]: {
        minWidth: "150px", // Smaller for small screens
    },
}));

const backgroundImageStyles = {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "auto",
    zIndex: "-1",
};

function People() {
    // Adjust breakpoints dynamically using `useMediaQuery`
    const isSmallScreen = useMediaQuery('(max-width:900px)'); // Adjust for earlier column drop
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <div className="form-container">
            <img src={london} className="HomeImageDesktop" alt="London by night" style={backgroundImageStyles} />
            <img src={londonSmall} className="HomeImageMobile" alt="London by night" style={backgroundImageStyles} />

            <Grid
                container
                spacing={isMobile ? 4 : 10}
                paddingTop={isMobile ? 5 : 10}
                paddingLeft={isMobile ? 2 : 5}
                paddingRight={isMobile ? 2 : 5}
            >
                {peopleList.map((person, index) => (
                    <Grid
                        key={index}
                        xs={12}
                        sm={12}
                        md={isSmallScreen ? 12 : 6}
                        lg={4}
                    >
                        <Item>
                            <Image src={person} alt={`Person ${index + 1}`} />
                        </Item>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}

export default People;
