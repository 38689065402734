import React from 'react';
import './ColourScheme.css'; // Import styles if you separate CSS

const ColourScheme = () => {
    // Array of color objects with hex codes and labels
    const colors = [
        { hex: '#191970', label: 'Midnight Blue' },
        { hex: '#4f1964', label: 'Royal Purple' },
        { hex: '#f6f7ec', label: 'Ivory' },
        { hex: '#e8d49c', label: 'Light Gold' },
        { hex: '#003807', label: 'Emerald Green' },
    ];

    return (
        <div className="circle-container">
            {colors.map((color, index) => (
                <div key={index} className="circle-label-wrapper">
                    <div
                        className="circle"
                        style={{ backgroundColor: color.hex }}
                    ></div>
                    <p className="circle-label">{color.label}</p>
                </div>
            ))}
        </div>
    );
};

export default ColourScheme;