import React from "react";
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import clothes from "../img/clothes.jpg";
import ColourScheme from "./ColourScheme";

const backgroundImageStyles = {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "auto",
    zIndex: "-1",
};

const cardStyles = {
    padding: "15px",
    marginLeft: "5%",
    marginRight: "5%",
    marginTop: "90px",
    backgroundColor: "rgba(10,30,104,0.6)",
    overflow: "auto",
    height: "auto"
};

const textStyles = {
    padding: "20px",
    textAlign: "center"
};

function Clothes() {
    return (
        <div className="scrolling-container">
            <img src={clothes} className="HomeImageDesktop" alt="Daniel and Siva at Happiness Forgets" style={backgroundImageStyles}/>
            <img src={clothes} className="HomeImageMobile" alt="Daniel and Siva at Happiness Forgets" style={backgroundImageStyles}/>

            <div className="text-container">
                <CssBaseline/>
                <Card variant="outlined" sx={cardStyles}>
                    <Typography variant="h4" component="h1" color={"ivory"} gutterBottom align={"center"}
                                sx={textStyles} fontFamily={"Goudy"}>
                        We're excited to share a little guidance on what to wear to our wedding, keeping comfort, style, and culture in mind
                    </Typography>
                    <Typography variant="h2" component="h1" color={"ivory"} gutterBottom align={"center"}
                                sx={textStyles} fontFamily={"Goudy"}>
                        For Men
                    </Typography>
                    <Typography variant="h4" component="h1" color={"ivory"} gutterBottom align={"center"}
                                sx={textStyles} fontFamily={"Avenir"}>
                        Feel free to opt for a button-up shirt or a kurta, paired with long trousers or a traditional veshti/dhoti. Formal covered shoes are ideal, and if you'd like to elevate your look with a tie or suit jacket, go for it!<br/> <br/>
                        What to avoid: Jeans, shorts, sandals, trainers, sneakers, running shoes, or t-shirts aren't quite the vibe for the day.
                    </Typography>
                    <Typography variant="h2" component="h1" color={"ivory"} gutterBottom align={"center"}
                                sx={textStyles} fontFamily={"Goudy"}>
                        For Women
                    </Typography>
                    <Typography variant="h4" component="h1" color={"ivory"} gutterBottom align={"center"}
                                sx={textStyles} fontFamily={"Avenir"}>
                        A dress or sari would be fabulous, and if you're feeling fancy, why not throw in a fascinator for some extra flair? Not your style? Rocking a shirt or suit is equally chic and totally welcome!<br/> <br/>
                        Kindly note: White is traditionally avoided for such occasions and Red is Siva's colour for the day. So we kindly ask you to steer clear of those two colours when choosing your outfit for the day.
                    </Typography>
                    <Typography variant="h2" component="h1" color={"ivory"} gutterBottom align={"center"}
                                sx={textStyles} fontFamily={"Goudy"}>
                        For Everyone
                    </Typography>
                    <Typography variant="h4" component="h1" color={"ivory"} gutterBottom align={"center"}
                                sx={textStyles} fontFamily={"Avenir"}>
                        We warmly invite you to wear your own national or cultural attire if you'd like. As an inter-cultural couple, it would be an honor to have a rich tapestry of cultures represented at our wedding!<br/><br/>
                        We do have a color scheme for the day, which you're welcome to follow if it suits your style:
                    </Typography>
                    <ColourScheme/>
                    <Typography variant="h5" component="h2" color={"ivory"} gutterBottom align={"center"}
                                sx={textStyles} fontFamily={"Avenir"}>
                        {'Please reach out to us with any questions on what to wear. We are happy to help!'}
                    </Typography>
                </Card>
            </div>
        </div>
    );
}

export default Clothes;
