import React from 'react';
import styled from "@emotion/styled";
import { Button } from "@mui/material";

const IvoryButton = styled(Button)(({ theme }) => ({
    color: '#f6f7ec',
    fontSize: '1.5vw',
    borderColor: '#f6f7ec',
    transition: 'transform 0.3s ease, background-color 0.3s ease',
    '&:hover': {
        backgroundColor: '#f6f7ec',
        color: '#000000',
        opacity: 0.75,
        transform: 'scale(1.1)',
        borderColor: '#000000'
    },
}));

  export default React.memo(IvoryButton);