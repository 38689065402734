import React from "react";
import { useTranslation } from "react-i18next";
import "../styles/Main.css";

function Home() {
    const { t } = useTranslation();
    return (
        <div className="HomeImage">
            <p className="Names">{t("daniel")} &<br /> {t("sivaranjani")}</p>
        </div>
    );
}

export default Home;
