import { Button, TextField } from "@mui/material";
import { useState, useEffect } from "react";
import SiteActive from "./SiteActive";
import axios from "axios";
import "../styles/Authentication.css";
import LogoutButton from './Logout';
import { MONGO_URL} from "./constants";

function Authentication(props) {
    const [incorrectPassword, setIncorrectPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [loggedIn, setLoggedIn] = useState(false);
    const siteActive = props.siteActive;

    useEffect(() => {
        // Check if the user is already logged in by looking at local storage
        const storedLoginState = localStorage.getItem("loggedIn");
        if (storedLoginState === "true") {
            setLoggedIn(true);
        }
    }, []);

    const handleIncorrectPassword = () => {
        setIncorrectPassword(true);
    };

    const login = async (event) => {
        event.preventDefault();

        try {
            const res = await axios.post(`${MONGO_URL}/authenticate`, {
                password: password,
            });
            const data = res.data;

            if (data === true) {
                setLoggedIn(true);
                localStorage.setItem("loggedIn", "true"); // Save login state to local storage
            } else {
                alert("Login failed");
                handleIncorrectPassword();
            }
        } catch (err) {
            alert("Login error, please try again: " + password.value);
            handleIncorrectPassword();
        }
    };

    const logout = () => {
        setLoggedIn(false);
        localStorage.removeItem("loggedIn"); // Remove login state from local storage
    };

    return (
        <>
            {loggedIn ? (
                <>
                    <SiteActive siteActive={siteActive} fullGuest={true} />
                    <LogoutButton
                            className="logout-button"
                            sx={{ position: "absolute", right: "10px", top: "10px", width: "50px", height: "50px" }}
                            onClick={logout}
                        >
                        Logout
                    </LogoutButton>
                </>
            ) : (
                <form onSubmit={login} className="Form">
                    <TextField
                        size="small"
                        type="password" // Changed to password input for security
                        label="Password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        helperText={incorrectPassword ? "Incorrect password." : ""}
                        error={incorrectPassword}
                    />
                    <Button variant="contained" type="submit" style={{ marginLeft: "10px" }}>
                        Submit
                    </Button>
                </form>
            )}
        </>
    );
}

export default Authentication;