import styled from "@emotion/styled";
import LogoutIcon from '@mui/icons-material/Logout';

const LogoutButton = styled(LogoutIcon)(({ theme }) => ({
    height: '40px',
    width: '40px',
    backgroundColor: '#f6f7ec',
    opacity: 0.8,
    color: 'black',
    fontSize: '24px',
    borderRadius: '50%',
    padding: '5px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    transition: 'background-color 0.3s ease, transform 0.2s ease',
    '&:hover': {
        transform: 'scale(1.1)',
        opacity: 1,
    },
}));

export default LogoutButton;
