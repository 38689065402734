import React, {useEffect, useState} from 'react';
import Home from './Home';
import RSVP from './RSVP';
import Recommendations from './Recommendations';
import People from './People';
import IvoryButton from "./IvoryButton";
import Details from "./Details";
import Clothes from "./Clothes";
import Registry from "./Registry";
import ReceptionRSVP from './ReceptionRSVP';
import ReceptionDetails from './ReceptionDetails';
import ReceptionClothes from './ReceptionClothes';
import { useTranslation } from 'react-i18next';

const buttonConfigs = [
    { label: 'details', component: 'details' },
    { label: 'rsvp', component: 'rsvp' },
    { label: 'clothes', component: 'clothes' },
    { label: 'registry', component: 'registry' },
    { label: 'recommendations', component: 'recommendations' },
    { label: 'people', component: 'people' }
];

function Main(props) {
    const { t, i18n } = useTranslation();
    const [currentComponent, setCurrentComponent] = useState('home');
    const [language, setLanguage] = useState('en');
    const [menuOpen, setMenuOpen] = useState(false);
    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 768);
    const fullGuest = props.fullGuest;

    const getComponent = () => {
        switch (currentComponent) {
            case 'home':
                return <Home />;
            case 'rsvp':
                return fullGuest ? <RSVP /> : <ReceptionRSVP />;
            case 'recommendations':
                return <Recommendations />;
            case 'people':
                return <People />;
            case 'details':
                return fullGuest ? <Details /> : <ReceptionDetails />;
            case 'registry':
                return <Registry />;
            case 'clothes':
                return fullGuest ? <Clothes /> : <ReceptionClothes />;
            default:
                return <Home />;
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth > 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleLanguage = () => {
        const newLanguage = language === 'en' ? 'ta' : 'en';
        i18n.changeLanguage(newLanguage);
        setLanguage(newLanguage);
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className="main-container">
            <div style={{position: "absolute", top: "10px", right: "70px"}}>
                <button className="language-toggle" onClick={toggleLanguage} >
                    {language === 'en' ? 'தமிழ்' : 'English'}
                </button>
            </div>

            {isWideScreen && (
            <div
                className="button-container"

                style={
                    currentComponent === "home"
                        ? { position: "absolute", top: "65%", left: "80%", transform: "translateY(-50%)", display: "flex", flexDirection: "column", gap: "50px" }
                        : { display: "flex", flexDirection: "row", gap: "10px" }
                }
            >
                {currentComponent === "home" ? (
                    buttonConfigs.map(({label, component}) => (
                        <IvoryButton
                            key={component}
                            variant="outlined"
                            onClick={() => setCurrentComponent(component)}
                        >
                            {t(label)}
                        </IvoryButton>
                    ))
                ) : (
                    <>
                        <IvoryButton
                            key="home"
                            variant="outlined"
                            onClick={() => setCurrentComponent("home")}
                        >
                            {t("home")}
                        </IvoryButton>
                        {buttonConfigs.map(({label, component}) => (
                            <IvoryButton
                                key={component}
                                variant="outlined"
                                onClick={() => setCurrentComponent(component)}
                                sx={{position: "relative", marginLeft: "10px"}}
                            >
                                {t(label)}
                            </IvoryButton>
                        ))}
                    </>
                )}
            </div>
                )}
            <div className="menu-container">

                <button className="hamburger" onClick={toggleMenu}>
                    ☰
                </button>
                {menuOpen && (
                    <div className="hamburger-menu">
                        <ul>
                            <li onClick={() => {
                                setCurrentComponent('home');
                                setMenuOpen(false);
                            }}>
                                {t('home')}
                            </li>
                            {buttonConfigs.map(({label, component}) => (
                                <li key={component} onClick={() => {
                                    setCurrentComponent(component);
                                    setMenuOpen(false);
                                }}>
                                    {t(label)}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>

            <div className="component-container">
                {getComponent()}
            </div>
        </div>
    );
}

export default Main;
