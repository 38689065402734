import chelsea from "../img/chelsea.jpg";
import React from "react";
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { useTranslation } from "react-i18next";

function Details() {
    const { t } = useTranslation();
    return <div className="container">
        <img src={chelsea} className="HomeImageDesktop" alt="Chelsea Bridge at night"/>
        <img src={chelsea} className="HomeImageMobile" alt="Chelsea Bridge at night"/>
        <div className="text-container">

            <CssBaseline/>
            <Card
                variant="outlined"
                sx={{
                    paddingLeft: "15px", paddingRight: "15px",
                    marginLeft: "5%", marginRight: "5%",
                    marginTop: "90px"
                }}
                style={{backgroundColor: "rgba(43,0,77,0.9)"}}
            >
                <Typography variant="h3" component="h1" color={"ivory"} gutterBottom align={"center"}
                            padding={"20px"} fontFamily={"Goudy"}>
                    {t("date")}
                </Typography>
                <Typography variant="h2" component="h1" color={"ivory"} gutterBottom align={"center"}
                            padding={"20px"} fontFamily={"Goudy"}>
                    {t("venue")}
                </Typography>
                <Typography variant="h4" component="h1" color={"ivory"} gutterBottom align={"center"}
                            padding={"20px"} fontFamily={"Avenir"}>
                    {t("time")}
                </Typography>
                <Typography variant="h5" component="h2" color={"ivory"} gutterBottom align={"center"}
                            padding={"20px"} fontFamily={"Avenir"}>
                    {t("meal")}<br/>
                    {t("snack")}
                </Typography>
            </Card>

        </div>
    </div>
}

export default Details;